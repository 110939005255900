.big_container{
    z-index: 0;
    position: relative;
    background-color: #043C53; 
    height: 90vh;
    max-width: 100vw;
}

.background_img{
    z-index: -4;
    width: 100%;
    height: 90vh;
    object-fit:cover;
    object-position: 70% 0%;
    
}

.background_layer{
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    background-color: rgb(4, 60, 83, 0.3);
}

.main_text{
    z-index: 1;
    position: absolute;
    color: white;
    font-weight: 600;
    top: 10%;
    left: 5%;
}

.brand_name{
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 1rem;
}

.footer_layer{
    z-index: 2;
    background-color: rgb(255, 255, 247, 0.4);
    position:absolute;
    display: flex;
    justify-content: center;
    bottom:0%;
    width: 100%;
    height: 8vh;

}

.button_footer{
    z-index: 2;
    width: 3rem;
    height: 3rem;
    position: absolute;
    transform: translate(0%, -50%);
    background: #0489A8;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    transition: all ease 0.5s;
}

.button_footer:hover{
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))
}

.button_link{
    color: #FFFFF7;
}

@media screen and (min-width: 768px){

.footer_layer{
    justify-content: flex-end;
    align-items: center;

}

.footer_text{
    font-size: 1.2rem;
    padding-right: 1rem;
    font-weight:800;
    
}

.background_img{
  
    object-position: bottom;
    
}
}

@media screen and (min-width: 992px){
    .brand_subtitle{
        font-size: 1.2rem;
    }
    
    .brand_name{
       
        font-size: 4rem;
      
    }
    .footer_text{
        letter-spacing: 0.1rem;
        
    }
    
}