.big_container{
    position: relative;
    background-color: #043C53; 
    height: auto;
    max-width: 100vw;
    
}

.container_1{
    height: 90vh;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
   
}

.section_title{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #F3E622;
    

}

.paragraph_container{
    display: flex;
    height: 100%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-content: stretch;
    justify-content:space-around;
}

.paragraph{
    line-height: 1.5rem;
}

.paragraph_title{
    font-weight: 800;
}

.paragraph_phrase{
    font-style: italic;
    color: #F3E622;
}

.container_2{
    margin-top:10vh;
    height: 90vh;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
}



/* classes for big devices */

.big_container_big_device{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
    height: 90vh;
}

.footer{
    height: 5vh;
    width: 100%;
    background-color: #0B111D;
    position: absolute;
    bottom: 0%;
}

.section_container{
    height: 100%;
    width: 100%;
    display: flex;
}

.section_1{
    height: 100%;
    width: 40%;
    background-color: #043C53;
}

.section_2{
    height: 100%;
    width: 60% ;
}

@media screen and (min-width: 768px){
    .section_1{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        background-image: url('../img/photos/allMountain/allMountain2.jpg');
        filter: blur(8px);
        -webkit-filter: opacity(85%);
        background-size: cover;
    }

    .section_title h3{
        font-size: 3rem;
        padding-bottom: 0.5rem;
    }

    .paragraph{
        margin-bottom: 8rem;
    }

    .paragraph_title{
        color: #FFFFF7;
        font-weight: 400;
        font-size: 1.3rem;
 
    }

    .section_2_container{
       
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 7rem;
        
    }

    .section_2_item{
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
        
 
        
       
       
    }

    .section_2_item p{
        font-size: 1.5rem;
        font-weight: 500;
        color: #0B111D;
        margin-right: 2rem;
       
    }

    .section_2_btn{
        width: 2.5rem;
        height: 2.5rem;
        background: #0489A8;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        border: none;
        color:#FFFFF7 ;
        text-decoration: none;
        cursor: pointer;
        transition: all ease 0.5s;
    }

    .section_2_btn:hover{
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))
    }


    .section_2_btn_link:visited, .section_2_btn_link:link, .section_2_btn_link:active{
        text-decoration: none;
        color: #FFFFF7;
    }

    .btnLink{
        margin: 0 auto;
    }

    .section_2_btn_prix{
        margin-top:4rem;
        margin: 0 auto;
        background: #0489A8;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        padding: 1rem 1rem;
        font-style: normal;
        font-weight: bold;
        color: #FFFFF7;
        border: none;
        cursor: pointer;
        transition: all ease 0.5s;
        text-decoration: none;
    }

    .section_2_btn_prix:hover{
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))
    }

    .sorties_info{
        z-index: 2;
        min-width: 35vw;
        max-width: 40vw;
        max-height: 25vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }
    .freeride_info{
        z-index: 2;
        min-width: 35vw;
        max-width: 40vw;
        max-height: 10vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }
    .enduro_info{
        z-index: 2;
        min-width: 25vw;
        max-width: 35vw;
        max-height: 10vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }
    .enfants_info{
        z-index: 2;
        min-width: 35vw;
        max-width: 40vw;
        max-height: 25vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }

    .initiation_info{
        z-index: 2;
        min-width: 40vw;
        max-width: 45vw;
        max-height:25vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }

    .info_container p {
        padding-bottom:1srem;
    }



}

