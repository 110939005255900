@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 16px;
	font: inherit;
	vertical-align: baseline;
	scroll-behavior: smooth;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


html {
    font-family: 'Nunito', 'Garamond', sans-serif;
    font-size: 1rem;
}
    
.Sorties_big_container__2vIW2{
    position: relative;
    background-color: #043C53; 
    height: auto;
    max-width: 100vw;
    
}

.Sorties_container_1__1xoIJ{
    height: 90vh;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
   
}

.Sorties_section_title__3aUHu{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #F3E622;
    

}

.Sorties_paragraph_container__3BmN6{
    display: flex;
    height: 100%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-content: stretch;
    justify-content:space-around;
}

.Sorties_paragraph__1FFIM{
    line-height: 1.5rem;
}

.Sorties_paragraph_title__cY1QX{
    font-weight: 800;
}

.Sorties_paragraph_phrase__3R0Rh{
    font-style: italic;
    color: #F3E622;
}

.Sorties_container_2__3u4cp{
    margin-top:10vh;
    height: 90vh;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
}



/* classes for big devices */

.Sorties_big_container_big_device__3KpmY{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
    height: 90vh;
}

.Sorties_footer__vmqnt{
    height: 5vh;
    width: 100%;
    background-color: #0B111D;
    position: absolute;
    bottom: 0%;
}

.Sorties_section_container__NqIpJ{
    height: 100%;
    width: 100%;
    display: flex;
}

.Sorties_section_1__oXxcZ{
    height: 100%;
    width: 40%;
    background-color: #043C53;
}

.Sorties_section_2__3Uquw{
    height: 100%;
    width: 60% ;
}

@media screen and (min-width: 768px){
    .Sorties_section_1__oXxcZ{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        background-image: url(/static/media/allMountain2.ac6e179e.jpg);
        filter: blur(8px);
        -webkit-filter: opacity(85%);
        background-size: cover;
    }

    .Sorties_section_title__3aUHu h3{
        font-size: 3rem;
        padding-bottom: 0.5rem;
    }

    .Sorties_paragraph__1FFIM{
        margin-bottom: 8rem;
    }

    .Sorties_paragraph_title__cY1QX{
        color: #FFFFF7;
        font-weight: 400;
        font-size: 1.3rem;
 
    }

    .Sorties_section_2_container__u7vY4{
       
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 7rem;
        
    }

    .Sorties_section_2_item__1WhM4{
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
        
 
        
       
       
    }

    .Sorties_section_2_item__1WhM4 p{
        font-size: 1.5rem;
        font-weight: 500;
        color: #0B111D;
        margin-right: 2rem;
       
    }

    .Sorties_section_2_btn__2vlZS{
        width: 2.5rem;
        height: 2.5rem;
        background: #0489A8;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        border: none;
        color:#FFFFF7 ;
        text-decoration: none;
        cursor: pointer;
        transition: all ease 0.5s;
    }

    .Sorties_section_2_btn__2vlZS:hover{
        -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))
    }


    .Sorties_section_2_btn_link__3jUgc:visited, .Sorties_section_2_btn_link__3jUgc:link, .Sorties_section_2_btn_link__3jUgc:active{
        text-decoration: none;
        color: #FFFFF7;
    }

    .Sorties_btnLink__3n11K{
        margin: 0 auto;
    }

    .Sorties_section_2_btn_prix__387Ir{
        margin-top:4rem;
        margin: 0 auto;
        background: #0489A8;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        padding: 1rem 1rem;
        font-style: normal;
        font-weight: bold;
        color: #FFFFF7;
        border: none;
        cursor: pointer;
        transition: all ease 0.5s;
        text-decoration: none;
    }

    .Sorties_section_2_btn_prix__387Ir:hover{
        -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))
    }

    .Sorties_sorties_info__2eQzA{
        z-index: 2;
        min-width: 35vw;
        max-width: 40vw;
        max-height: 25vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }
    .Sorties_freeride_info__17GXR{
        z-index: 2;
        min-width: 35vw;
        max-width: 40vw;
        max-height: 10vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }
    .Sorties_enduro_info__1sPYG{
        z-index: 2;
        min-width: 25vw;
        max-width: 35vw;
        max-height: 10vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }
    .Sorties_enfants_info__2sUMn{
        z-index: 2;
        min-width: 35vw;
        max-width: 40vw;
        max-height: 25vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }

    .Sorties_initiation_info__2RInj{
        z-index: 2;
        min-width: 40vw;
        max-width: 45vw;
        max-height:25vh;
        position: absolute;
        top: 3rem;
        left: 0rem;
        border: 1px solid #0489A8;
        background-color: #FFFFF7 ;
        -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        padding: 1rem;
        
    }

    .Sorties_info_container__3N1Im p {
        padding-bottom:1srem;
    }



}


.Stages_big_container__3TK3T{
    position: relative;
    background-color: #043C53; 
    height: auto;
    max-width: 100vw;
}

.Stages_container_1__BtHyf{
    height: 90vh;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
   
}

.Stages_section_title__1RG1r{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #F3E622;
    

}

.Stages_paragraph_container__RL72o{
    display: flex;
    height: 50%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-content: stretch;
    justify-content:space-around;
}

.Stages_paragraph__1bpye{
    line-height: 1.5rem;
}

.Stages_paragraph_title__1V54g{
    font-weight: 800;
}

.Stages_paragraph_phrase__d08oJ{
    font-style: italic;
    color: #F3E622;
}


/* classes for big devices */

.Stages_big_container_big_device__11in3{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
    height: 90vh;
}

.Stages_footer__3IVY5{
    height: 5vh;
    width: 100%;
    background-color: #0B111D;
    position: absolute;
    bottom: 0%;
}

.Stages_section_container__3NJu3{
    height: 100%;
    width: 100%;
    display: flex;
}

.Stages_section_1__2wAlM{
    height: 100%;
    width: 40%;
    background-color: #043C53;
}

.Stages_section_2__2nHXj{
    max-height: 100%;
    width: 60%;
    background-color: white;
}

.Stages_img_section__WzUmM{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
}

@media screen and (min-width: 768px){
    
    .Stages_section_title__1RG1r {
        height: auto;
        display: flex;
        justify-content: center;
        padding: 2rem
        0rem 0.5rem 0rem;
        font-size: 2rem;
        color: #F3E622;
    
    }


.Stages_paragraph_container__RL72o{
    display: flex;
    height: 50%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-content: stretch;
    justify-content:space-around;
}

.Stages_paragraph__1bpye{
   
    line-height: 1.5rem;
    color:#FFFFF7 ;
    font-size: 1.4rem;
}

.Stages_paragraph_title__1V54g{
    padding-bottom: 1rem;
    font-weight: 800;
    font-size: 1.2rem;
    color: #FFFFF7;
}

.Stages_paragraph_phrase__d08oJ{
    font-size: 1.2rem;
    line-height: 2rem;
    font-style:italic;
    font-weight: 300;
    color: #F3E622 ;
}
}
.Location_big_container__1qPMA{
    position: relative;
    background-color: #043C53; 
    height: auto;
    max-width: 100vw;
}

.Location_container_1__mFyUU{
    height: 90vh;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
   
}

.Location_section_title__6AXcr{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #F3E622;
    

}


.Location_paragraph_container__HPzlv{
    display: flex;
    height: 100%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-content: stretch;
    justify-content:space-around;
}

.Location_paragraph__3xhOe{
    line-height: 1.5rem;
}

.Location_paragraph_title__21FXL{
    font-weight: 800;
    padding-bottom: 1rem;
}


.Location_paragraph_phrase__10mx4{
    text-align: center;
    font-style: italic;
    color: #F3E622;
}

.Location_button_location__1ySVo{
    margin: 0 auto;
    background: #0489A8;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    padding: 1rem 1rem;
    font-style: normal;
    font-weight: bold;
    color: #FFFFF7;
    border: none;
    cursor: pointer;
    transition: all ease 0.5s;
    text-decoration: none;
}


.Location_button_location__1ySVo:hover{
    -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))
}

.Location_button_link__2pYqz{
    color: #FFFFF7;
}


/* classes for big devices */

.Location_big_container_big_device__2Tusl{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
    height: 90vh;
}

.Location_footer__2EHOM{
    height: 5vh;
    width: 100%;
    background-color: #0B111D;
    position: absolute;
    bottom: 0%;
}

.Location_section_container__ouyeO{
    height: 100%;
    width: 100%;
    display: flex;
}

.Location_section_1__2SAC3{
    height: 100%;
    width: 60%;
    background-color: #043C53;
}

.Location_section_2__BUiYj{
    max-height: 100%;
    width: 40%;
    background-color: white;
}

@media screen and (min-width: 768px){

    .Location_section_title__6AXcr {
        height: auto;
        display: flex;
        justify-content: center;
        padding: 2rem
        0rem 0.5rem 0rem;
        font-size: 2rem;
        color: #F3E622;
    
    }

    .Location_section_title__6AXcr h3{
        font-size: 3rem;
    }
    .Location_paragraph_container__HPzlv{
        display: flex;
        height: 80%;
        padding: 0rem 2rem;
        flex-direction: column;
        align-content: stretch;
        justify-content:space-around;
        color: #FFFFF7;
    }

    .Location_paragraph__3xhOe{
        line-height: 1.5rem;
    }

    .Location_img_section__1wG8h{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: right;
    }
}

@media screen and (min-width: 992px){
    .Location_paragraph_title__21FXL{
        font-size: 1.2rem;
    }
    .Location_paragraph_container__HPzlv{
        font-size: 1.2rem;
    }
}
.Contact_big_container__1b4zi{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
}

.Contact_container_1__1WDFY{
    height: 90vh;
    color: #043C53;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-around;
   
}

.Contact_section_title__1huUG{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #0489A8;
    

}


.Contact_logo_img__24trQ{
    padding-bottom: 0.8rem;
    width: 7rem;

}

.Contact_container_map__22iqh{
    width: 90vw;
    height: 30vh;
}
.Contact_map__Qcx94{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.Contact_paragraph_container__2Oj_W{
    display: flex;
    height: 90%;
    flex-direction: column;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    line-height: 1.5rem;
    font-size: 0.8rem;
}

.Contact_paragraph_phrase__3rc97{
    font-style: italic;
    color: #978f15;
}

.Contact_paragraph_title__1yRt6{
    padding-top: 0.5rem;
    font-weight: 800;
}

/* classes for big devices */

.Contact_big_container_big_device__33z7l{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
    height: 90vh;
}

.Contact_footer__DDc6e{
    height: 5vh;
    width: 100%;
    background-color: #0B111D;
    position: absolute;
    bottom: 0%;
}

.Contact_section_container__24aVz{
    height: 100%;
    width: 100%;
    display: flex;
}

.Contact_section_1__gzFSp{
    height: 100%;
    width: 60%;
    background-color: #043C53;
}

.Contact_section_2__uWQZz{
    height: 100%;
    width: 40%;
    background-color: white;
}

@media screen and (min-width: 768px){

    .Contact_section_title__1huUG{
    
    display: flex;
    justify-content: center;
    padding: 2rem
    0rem 2rem 0rem;
    font-size: 2rem;
    color: #F3E622;

    }
    .Contact_section_title__1huUG h3{
        font-size: 3rem;
    }

    .Contact_container_map__22iqh{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 70%;
    }
    .Contact_map__Qcx94{
        
        width: 90%;
        height: 100%;
        overflow: hidden;
    }


    .Contact_paragraph_container__2Oj_W{
        height: 60%;
        line-height: 2rem;
        font-size: 1rem;
    }

    .Contact_container_logo__1p-Qb{
        height: 40%;
        display: flex;
        justify-content: center;
    }

    .Contact_logo_img__24trQ{
        padding-bottom: 0.5rem;
        width: 10rem;
    
    }
}
.Gallerie_big_container__3uQsA{
    position: relative;
    background-color: #043C53; 
    height: auto;
    max-width: 100vw;
}

.Gallerie_container_1__j7z7q{
    height: auto;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
   
}

.Gallerie_section_title__38W93{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #F3E622;
    

}

.Gallerie_grid_container__3LRWy {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
    grid-gap: 1rem;
    padding: 1rem;
}

.Gallerie_grid_item__NyHgz {
    width:100%;
    height: 100%;
    position: relative;
}

.Gallerie_grid_item__NyHgz img {
    width:100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    transition: all ease 0.8s;
}

.Gallerie_grid_item__NyHgz img:hover {
 
    -webkit-transform: scale(1.04);
 
            transform: scale(1.04);
}

@media screen and (min-width: 768px){
    .Gallerie_section_title__38W93 h3{
        font-size: 3rem;
    }
}

a:hover, a:visited, a:link, a:active{
    text-decoration: none;
    color: #043C53;
}

.MainNavigation_logo_img__Exk9e{
    width: 3rem;

}

.MainNavigation_nav__1UI7K{
    
    height: 10vh;
    padding-left:  0.5rem;
    display: flex;
    justify-content: space-between;
    align-items:center;
    background-color: #FFFFF7;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
}

.MainNavigation_navBar__37YIE{
    position: absolute;
    top:10vh;
    z-index: 100;
    height: 90vh;
    width:100vw;
    background-color: #043C53;
}

.MainNavigation_nav_title__3--bO{
    color: #0489A8;
    font-size: 1.5rem;
}

.MainNavigation_nav_items__2teXT{
    z-index: 100;
    font-size: 1.5rem;
    display: flex;
    padding-left: 1.5rem;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
}

.MainNavigation_nav_item__1fWDM{
    text-decoration: none;
    z-index: 100;
    font-weight: 600;
    color: #F3E622;
}

.MainNavigation_nav_item__1fWDM:hover{
    z-index: 100;
    color:#FFFFF7;
}

.MainNavigation_hamburger__1aDVi{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 4rem;
    color: #043C53;
    cursor: pointer;
    
}

.MainNavigation_navbar_pc__1w4D9{
    display:none;
}

@media screen and (min-width: 768px){
    .MainNavigation_hamburger_icon__3r_IG{
        display:none;
    }

    .MainNavigation_hamburger__1aDVi{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 30rem;
        color: #043C53;
        cursor:auto;
    }

    .MainNavigation_navbar_pc__1w4D9{
        display:flex;
        flex-basis: 50rem;
        flex-grow: 2;
        justify-content: space-around;
   
       
    }

    .MainNavigation_navbar_pc_link__O_ztw{
        text-decoration: none;
    }
    
    .MainNavigation_navbar_pc_item__3KN0a{
        cursor: pointer;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 600;
        padding-right: 2rem;
    }

    .MainNavigation_navbar_pc_item__3KN0a:hover{
       color:#F3E622;
       transition: all ease 0.3s;
    }

    .MainNavigation_logo_img__Exk9e{
        width: 3.5rem;
    
    }
   
}

@media screen and (min-width: 992px){
    .MainNavigation_logo_img__Exk9e{
        width: 4.5rem;
    
    }
}

@media screen and (min-width: 1200px){
    .MainNavigation_navbar_pc_item__3KN0a{
     
        font-size: 1.4rem;
      
    }
}

.Accueil_big_container__1MJYL{
    z-index: 0;
    position: relative;
    background-color: #043C53; 
    height: 90vh;
    max-width: 100vw;
}

.Accueil_background_img__3nESg{
    z-index: -4;
    width: 100%;
    height: 90vh;
    object-fit:cover;
    object-position: 70% 0%;
    
}

.Accueil_background_layer__1WgWG{
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    background-color: rgb(4, 60, 83, 0.3);
}

.Accueil_main_text__1DUpm{
    z-index: 1;
    position: absolute;
    color: white;
    font-weight: 600;
    top: 10%;
    left: 5%;
}

.Accueil_brand_name__2w4ht{
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 1rem;
}

.Accueil_footer_layer__1vKpZ{
    z-index: 2;
    background-color: rgb(255, 255, 247, 0.4);
    position:absolute;
    display: flex;
    justify-content: center;
    bottom:0%;
    width: 100%;
    height: 8vh;

}

.Accueil_button_footer__12ptm{
    z-index: 2;
    width: 3rem;
    height: 3rem;
    position: absolute;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    background: #0489A8;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    transition: all ease 0.5s;
}

.Accueil_button_footer__12ptm:hover{
    -webkit-filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))
}

.Accueil_button_link__22fOL{
    color: #FFFFF7;
}

@media screen and (min-width: 768px){

.Accueil_footer_layer__1vKpZ{
    justify-content: flex-end;
    align-items: center;

}

.Accueil_footer_text__FtW4z{
    font-size: 1.2rem;
    padding-right: 1rem;
    font-weight:800;
    
}

.Accueil_background_img__3nESg{
  
    object-position: bottom;
    
}
}

@media screen and (min-width: 992px){
    .Accueil_brand_subtitle__3YdxG{
        font-size: 1.2rem;
    }
    
    .Accueil_brand_name__2w4ht{
       
        font-size: 4rem;
      
    }
    .Accueil_footer_text__FtW4z{
        letter-spacing: 0.1rem;
        
    }
    
}
.Prices_imgContainer__28zvd{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Prices_imgPrix__1Nyuv{
    max-width: 70rem;
    padding-top: 1rem;
}
