.big_container{
    position: relative;
    background-color: #043C53; 
    height: auto;
    max-width: 100vw;
}

.container_1{
    height: 90vh;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
   
}

.section_title{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #F3E622;
    

}


.paragraph_container{
    display: flex;
    height: 100%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-content: stretch;
    justify-content:space-around;
}

.paragraph{
    line-height: 1.5rem;
}

.paragraph_title{
    font-weight: 800;
    padding-bottom: 1rem;
}


.paragraph_phrase{
    text-align: center;
    font-style: italic;
    color: #F3E622;
}

.button_location{
    margin: 0 auto;
    background: #0489A8;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    padding: 1rem 1rem;
    font-style: normal;
    font-weight: bold;
    color: #FFFFF7;
    border: none;
    cursor: pointer;
    transition: all ease 0.5s;
    text-decoration: none;
}


.button_location:hover{
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25))
}

.button_link{
    color: #FFFFF7;
}


/* classes for big devices */

.big_container_big_device{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
    height: 90vh;
}

.footer{
    height: 5vh;
    width: 100%;
    background-color: #0B111D;
    position: absolute;
    bottom: 0%;
}

.section_container{
    height: 100%;
    width: 100%;
    display: flex;
}

.section_1{
    height: 100%;
    width: 60%;
    background-color: #043C53;
}

.section_2{
    max-height: 100%;
    width: 40%;
    background-color: white;
}

@media screen and (min-width: 768px){

    .section_title {
        height: auto;
        display: flex;
        justify-content: center;
        padding: 2rem
        0rem 0.5rem 0rem;
        font-size: 2rem;
        color: #F3E622;
    
    }

    .section_title h3{
        font-size: 3rem;
    }
    .paragraph_container{
        display: flex;
        height: 80%;
        padding: 0rem 2rem;
        flex-direction: column;
        align-content: stretch;
        justify-content:space-around;
        color: #FFFFF7;
    }

    .paragraph{
        line-height: 1.5rem;
    }

    .img_section{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: right;
    }
}

@media screen and (min-width: 992px){
    .paragraph_title{
        font-size: 1.2rem;
    }
    .paragraph_container{
        font-size: 1.2rem;
    }
}