.big_container{
    position: relative;
    background-color: #043C53; 
    height: auto;
    max-width: 100vw;
}

.container_1{
    height: 90vh;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
   
}

.section_title{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #F3E622;
    

}

.paragraph_container{
    display: flex;
    height: 50%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-content: stretch;
    justify-content:space-around;
}

.paragraph{
    line-height: 1.5rem;
}

.paragraph_title{
    font-weight: 800;
}

.paragraph_phrase{
    font-style: italic;
    color: #F3E622;
}


/* classes for big devices */

.big_container_big_device{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
    height: 90vh;
}

.footer{
    height: 5vh;
    width: 100%;
    background-color: #0B111D;
    position: absolute;
    bottom: 0%;
}

.section_container{
    height: 100%;
    width: 100%;
    display: flex;
}

.section_1{
    height: 100%;
    width: 40%;
    background-color: #043C53;
}

.section_2{
    max-height: 100%;
    width: 60%;
    background-color: white;
}

.img_section{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
}

@media screen and (min-width: 768px){
    
    .section_title {
        height: auto;
        display: flex;
        justify-content: center;
        padding: 2rem
        0rem 0.5rem 0rem;
        font-size: 2rem;
        color: #F3E622;
    
    }


.paragraph_container{
    display: flex;
    height: 50%;
    padding: 0rem 1rem;
    flex-direction: column;
    align-content: stretch;
    justify-content:space-around;
}

.paragraph{
   
    line-height: 1.5rem;
    color:#FFFFF7 ;
    font-size: 1.4rem;
}

.paragraph_title{
    padding-bottom: 1rem;
    font-weight: 800;
    font-size: 1.2rem;
    color: #FFFFF7;
}

.paragraph_phrase{
    font-size: 1.2rem;
    line-height: 2rem;
    font-style:italic;
    font-weight: 300;
    color: #F3E622 ;
}
}