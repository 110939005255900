
a:hover, a:visited, a:link, a:active{
    text-decoration: none;
    color: #043C53;
}

.logo_img{
    width: 3rem;

}

.nav{
    
    height: 10vh;
    padding-left:  0.5rem;
    display: flex;
    justify-content: space-between;
    align-items:center;
    background-color: #FFFFF7;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
}

.navBar{
    position: absolute;
    top:10vh;
    z-index: 100;
    height: 90vh;
    width:100vw;
    background-color: #043C53;
}

.nav_title{
    color: #0489A8;
    font-size: 1.5rem;
}

.nav_items{
    z-index: 100;
    font-size: 1.5rem;
    display: flex;
    padding-left: 1.5rem;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
}

.nav_item{
    text-decoration: none;
    z-index: 100;
    font-weight: 600;
    color: #F3E622;
}

.nav_item:hover{
    z-index: 100;
    color:#FFFFF7;
}

.hamburger{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 4rem;
    color: #043C53;
    cursor: pointer;
    
}

.navbar_pc{
    display:none;
}

@media screen and (min-width: 768px){
    .hamburger_icon{
        display:none;
    }

    .hamburger{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 30rem;
        color: #043C53;
        cursor:auto;
    }

    .navbar_pc{
        display:flex;
        flex-basis: 50rem;
        flex-grow: 2;
        justify-content: space-around;
   
       
    }

    .navbar_pc_link{
        text-decoration: none;
    }
    
    .navbar_pc_item{
        cursor: pointer;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 600;
        padding-right: 2rem;
    }

    .navbar_pc_item:hover{
       color:#F3E622;
       transition: all ease 0.3s;
    }

    .logo_img{
        width: 3.5rem;
    
    }
   
}

@media screen and (min-width: 992px){
    .logo_img{
        width: 4.5rem;
    
    }
}

@media screen and (min-width: 1200px){
    .navbar_pc_item{
     
        font-size: 1.4rem;
      
    }
}
