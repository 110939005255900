.big_container{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
}

.container_1{
    height: 90vh;
    color: #043C53;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-around;
   
}

.section_title{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #0489A8;
    

}


.logo_img{
    padding-bottom: 0.8rem;
    width: 7rem;

}

.container_map{
    width: 90vw;
    height: 30vh;
}
.map{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.paragraph_container{
    display: flex;
    height: 90%;
    flex-direction: column;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    line-height: 1.5rem;
    font-size: 0.8rem;
}

.paragraph_phrase{
    font-style: italic;
    color: #978f15;
}

.paragraph_title{
    padding-top: 0.5rem;
    font-weight: 800;
}

/* classes for big devices */

.big_container_big_device{
    position: relative;
    background-color: white; 
    height: auto;
    max-width: 100vw;
    height: 90vh;
}

.footer{
    height: 5vh;
    width: 100%;
    background-color: #0B111D;
    position: absolute;
    bottom: 0%;
}

.section_container{
    height: 100%;
    width: 100%;
    display: flex;
}

.section_1{
    height: 100%;
    width: 60%;
    background-color: #043C53;
}

.section_2{
    height: 100%;
    width: 40%;
    background-color: white;
}

@media screen and (min-width: 768px){

    .section_title{
    
    display: flex;
    justify-content: center;
    padding: 2rem
    0rem 2rem 0rem;
    font-size: 2rem;
    color: #F3E622;

    }
    .section_title h3{
        font-size: 3rem;
    }

    .container_map{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 70%;
    }
    .map{
        
        width: 90%;
        height: 100%;
        overflow: hidden;
    }


    .paragraph_container{
        height: 60%;
        line-height: 2rem;
        font-size: 1rem;
    }

    .container_logo{
        height: 40%;
        display: flex;
        justify-content: center;
    }

    .logo_img{
        padding-bottom: 0.5rem;
        width: 10rem;
    
    }
}