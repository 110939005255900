.big_container{
    position: relative;
    background-color: #043C53; 
    height: auto;
    max-width: 100vw;
}

.container_1{
    height: auto;
    color: #FFFFF7;
    display: flex;
    flex-direction:column;
   
}

.section_title{
    
    margin: 0 auto;
    padding: 1.5rem
    0rem 0.5rem 0rem;
    font-size: 2rem;
    color: #F3E622;
    

}

.grid_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
    grid-gap: 1rem;
    padding: 1rem;
}

.grid_item {
    width:100%;
    height: 100%;
    position: relative;
}

.grid_item img {
    width:100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    transition: all ease 0.8s;
}

.grid_item img:hover {
 
    transform: scale(1.04);
}

@media screen and (min-width: 768px){
    .section_title h3{
        font-size: 3rem;
    }
}